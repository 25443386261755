import type { JSX } from 'solid-js'
import { createMemo } from 'solid-js'
import { usePageContext } from 'vike-solid/usePageContext'

import { useI18n } from '#/i18n/I18nProvider'

export type LinkProps = {
  href: string
} & JSX.HTMLAttributes<HTMLAnchorElement>

export function Link(props: LinkProps) {
  const pageContext = usePageContext()

  const i18n = useI18n()

  const isActive = createMemo(() =>
    props.href === '/'
      ? pageContext.urlPathname === props.href
      : pageContext.urlPathname.startsWith(props.href),
  )
  return (
    <a
      draggable={false}
      {...props}
      href={i18n.pathnameWithLanguage({
        pathname: props.href,
      })}
      classList={{
        'is-active': isActive(),
        ...props.classList,
      }}
    />
  )
}
